.bn-controls-drawer {
  &__nav {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgb(255, 255, 255, 100%) 0%,
      rgb(255, 255, 255, 0%) 100%
    );
    bottom: 0;
    height: 10.125rem;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: sticky;
    transform: translateY(2rem);
    transition: all 0.2s ease-out;
    visibility: hidden;
    width: 100%;
    z-index: 9999;

    &--show {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }

  &__nav-controls {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: inherit;
    justify-content: center;
  }

  &__btn {
    pointer-events: initial;
  }

  &__nav-label {
    margin-bottom: var(--spacing);
  }
}
