.bn-pill {
  --secondary-blue: #6aa4f3;
  --secondary-blue-bg: #eef5ff;

  align-items: baseline;
  background-color: var(--grey-800);
  border-radius: var(--box-border-radius-s);
  display: flex;
  font-size: var(--font-xs);
  padding: 0.125rem 0.375rem;
  transition: background-color 0.2s ease-out;
  white-space: nowrap;

  &--success-20 {
    background-color: rgb(var(--bn-success-rgb), 0.2);
  }

  &--success {
    background-color: var(--bn-success-bg);
  }

  &--grey {
    background-color: var(--grey-200);
  }

  &--danger {
    background-color: var(--bn-danger-bg);
    color: white;

    .bn-pill__icon {
      filter: var(--bn-white-filter);
    }
  }

  &--info-20 {
    background-color: rgb(var(--bn-light-blue-rgb), 0.2);
    color: var(--bn-dark-blue);
  }

  &--secondary-blue {
    background-color: var(--secondary-blue-bg);
    border: 1px solid var(--secondary-blue);
    color: var(--bn-text);

    .bn-pill {
      &__count {
        border-color: currentcolor;
        color: var(--secondary-blue);
      }
    }
  }

  &--secondary-danger {
    background-color: rgb(var(--bn-danger-rgb), 0.05);
    border: 1px solid var(--bn-danger-bg);
    color: var(--bn-text);

    .bn-pill {
      &__count {
        border-color: currentcolor;
        color: var(--bn-danger-bg);
      }
    }
  }

  &--large {
    border-radius: var(--box-border-radius);
    font-size: 1rem;
    padding: var(--spacing-xxxs) var(--spacing);
  }

  &--padding-right-2px {
    padding-right: 2px;
  }

  &--pr-spacing-xxs {
    padding-right: var(--spacing-xxs);
  }

  &__text-spacing {
    margin-right: var(--spacing-xxs);
  }

  &__icon {
    margin-right: var(--spacing-xxxs);
  }

  &__icon img {
    object-fit: contain;
  }

  &__count {
    --base: 1.5rem;

    align-items: center;
    border: 1px solid var(--bn-text);
    border-radius: 50%;
    display: flex;
    font-size: var(--font-sm);
    font-weight: var(--font-semi-bold);
    height: var(--base);
    justify-content: center;
    margin-left: var(--spacing-xxs);
    width: var(--base);
  }

  &__cursor-pointer {
    cursor: pointer;
  }

  @media (--screen-xs) {
    font-size: var(--font-xxs);
  }
}
