.bn-checkbox {
  --checkbox-color-rgb: var(--bn-light-blue-rgb);
  --checkbox-color: var(--bn-light-blue);
  --checkbox-filter: initial;

  align-items: center;
  display: flex;
  gap: var(--spacing-xxs);

  &--right-aligned {
    justify-content: space-between;
    width: 100%;

    .bn-checkbox {
      &__label {
        flex: 1;
        order: 1;
      }

      &__input {
        order: 2;
      }
    }
  }

  &--success {
    --checkbox-color-rgb: var(--bn-success-rgb);
    --checkbox-color: var(--bn-success-bg);
    --checkbox-filter: var(--bn-success-filter);
  }

  &--warning {
    --checkbox-color-rgb: var(--bn-warning-rgb);
    --checkbox-color: var(--bn-warning-bg);
    --checkbox-filter: var(--bn-warning-filter);
  }

  &__label {
    cursor: pointer;
  }

  &__input {
    appearance: none;
    background-color: rgb(var(--checkbox-color-rgb), 0.08);
    border: 1px solid var(--checkbox-color);
    border-radius: 0.25rem;
    cursor: pointer;
    display: inline-block;
    height: 1.25rem;
    position: relative;
    width: 1.25rem;

    &::after {
      align-items: center;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 8l4.5 5L16 2.5' stroke='%2376A8F4' stroke-width='4' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      background-position: center;
      background-repeat: no-repeat;
      content: "";
      display: flex;
      filter: var(--checkbox-filter);
      height: inherit;
      justify-content: center;
      opacity: 0;
      transform: translateY(4px) scale(0.4);
      transition: all 0.2s ease-in-out;
      width: inherit;
    }

    &:checked {
      box-shadow:
        0 1px 2px rgb(0, 0, 0, 5%),
        inset 0 -15px 10px -12px rgb(0, 0, 0, 5%),
        inset 15px 10px -12px rgb(255, 255, 255, 10%);
      color: #99a1a7;

      &::after {
        opacity: 1;
        transform: translateY(0) scale(0.6);
      }
    }
  }
}
