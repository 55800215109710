.bn-progress-bar {
  appearance: none;

  /* 
    The background colour has to be here because Firefox does not use the 
    ::-webkit-progress-bar pseudo-element. Unlike the other browsers.
   */
  background-color: rgb(var(--bn-light-blue-rgb), 0.15);
  /* stylelint-disable-next-line property-no-vendor-prefix */
  border: none;
  border-radius: 10rem;
  display: block;
  height: 1.2rem;
}

.bn-progress-bar::-webkit-progress-bar {
  background-color: rgb(var(--bn-light-blue-rgb), 0.15);
  border-radius: 10rem;
  height: 1.2rem;
}

.bn-progress-bar::-webkit-progress-value {
  background-color: var(--bn-light-blue);
  border-radius: 10rem;
  height: 1.2rem;
}

/*
  This is firefox being difficult and not using the ::-webkit-progress-bar
  pseudo-element. Unlike the other browsers. However, if I try to use the 
  selectors correctly (i.e. .bn-progress-bar::-moz-progress-bar, .bn-progress-bar::-webkit-progress-value)
  then it breaks in Safari and turns green
 */
.bn-progress-bar::-moz-progress-bar {
  background-color: var(--bn-light-blue);
  border-radius: 10rem;
  height: 1.2rem;
}
